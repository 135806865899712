<template>
  <div class="embed">
    <player
        v-if="media"
        :type="media.media_type"
        :options="getPlayerOptions(media)"
        :key="media.uid"/>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Player from "@/components/Player";

export default {
  name: "Embed",
  components: {
    Player
  },
  data() {
    return {
      media: null
    }
  },
  methods: {
    loadMedia() {
      let query = `query($uid: String!) {
        mediaDetail(uid: $uid) {
          link
          media_type
        }
      }`;

      ApiService.graphql(query, {uid: this.$route.params.uid})
          .then(({data}) => {
            if (data.data && data.data.mediaDetail) {
              this.media = data.data.mediaDetail;
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    getPlayerOptions(media) {
      return {
        autoplay: false,
        loop: false,
        controls: true,
        fill: true,
        sources: [
          {
            src: media.link,
            type: media.mime_type
          }
        ]
      }
    }
  },
  mounted() {
    this.loadMedia();
  }
}
</script>
